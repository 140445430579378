import React from 'react';
import styles from '../css/ProductList.module.css';
import { motion } from 'framer-motion'
import { useNavigate } from 'react-router-dom';

const ProductList = ({ products }) => {

    let navigate = useNavigate();

    const productClick = (id) => {
        navigate('/product/' + id);
    }

    return (
        <div className={styles.container} >
            {
                products && products.map((item, index) => {
                    return (
                        <motion.div
                            initial={{
                                x: -50, y: -50, opacity: 0,
                            }}
                            animate={{
                                x: 0, y: 0, opacity: 1, transition: {
                                    delay: index * 0.02
                                }
                            }}
                            key={index}
                            className={styles.productContainer}
                            onClick={() => productClick(item.id)}
                        >
                            <div className={styles.imageContainer}>
                                <img src={item.image} alt="" />
                            </div>
                            <div className={styles.label}>
                                <p>{item.title}</p>
                                <p>{item.price}</p>
                            </div>
                        </motion.div>
                    )
                })
            }
        </div>
    );
}

export default ProductList;