import React from 'react';
import styles from '../css/Takeaway.module.css';

import logo from '../images/logo.png';
import bag from '../images/takeaway.png';
import shop from '../images/storefront.png';

import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom'
import { useRecoilState } from 'recoil';
import { Order } from '../State';


const Takeaway = () => {

    const navigate = useNavigate();
    const [order, setOrder] = useRecoilState(Order);

    const anim = {
        hidden: {
            opacity: 0,
            scale: 0,
        },
        visible: {
            opacity: 1,
            scale: 1,
            transition: {
                duration: 0.5
            }
        }
    }

    const onItemSelection = (choice) => {
        let myOrder = {
            ...order,
            type: choice,
        }

        setOrder(myOrder);
        navigate('/menu/deals');
    }

    return (
        <div className={styles.container}>
            <div className={styles.banner}>
                <div className={styles.topBar}></div>
                <motion.div
                    className={styles.logoContainer}
                    variants={anim}
                    initial="hidden"
                    animate="visible"
                >
                    <img src={logo} alt="Chicken logo" />
                </motion.div>
                <p
                    variants={anim}
                    initial="hidden"
                    animate="visible">
                    Please select your eating option.
                </p>
            </div>
            <div className={styles.options}>
                <motion.div
                    className={styles.optionContainer}
                    variants={anim}
                    initial="hidden"
                    animate="visible"
                    onClick={() => onItemSelection('Dine In')}
                >
                    <img width="250px" src={shop} alt="shop" />
                    <h3>DINE-IN</h3>
                </motion.div>
                <motion.div
                    className={styles.optionContainer}
                    variants={anim}
                    initial="hidden"
                    animate="visible"
                    onClick={() => onItemSelection('Takeaway')}
                >
                    <img width="250px" src={bag} alt="shop" />
                    <h3>TAKEAWAY</h3>
                </motion.div>
            </div>
            <motion.div
                className={styles.button}
                variants={anim}
                initial="hidden"
                animate="visible"
                onClick={() => navigate('/')}
            >
                Home
            </motion.div>
        </div>
    );
}

export default Takeaway;