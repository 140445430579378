import React from 'react';
import styles from '../css/Menu.module.css';
import ImageSlider from '../components/ImageSlider';
import Footer from '../components/Footer';

import promo1 from '../images/promo/promo1.png';
import promo2 from '../images/promo/promo2.png';
import promo3 from '../images/promo/promo3.png';

import { Outlet, useLocation, useNavigate } from 'react-router-dom';

const Menu = () => {

    let location = useLocation();
    let navigate = useNavigate();

    const images = [
        {
            src: promo1,
            alt: 'promo1',
        },
        {
            src: promo2,
            alt: 'promo2',
        },
        {
            src: promo3,
            alt: 'promo2',
        }
    ];

    const getTabStyles = (label) => {
        let t = `${styles.tabItem}`;
        let path = location.pathname;
        if (path.includes(label.toLowerCase())) {
            return `${styles.tabItem} ${styles.active}`;
        }

        return t;
    }

    return (
        <div className={styles.container}>
            <div className={styles.promo}>
                <ImageSlider images={images} stretch={true} />
            </div>
            <div className={styles.menuContainer}>
                <div className={styles.tabs}>
                    <div className={getTabStyles('deals')} onClick={() => navigate('deals')} >Hot Deals</div>
                    <div className={getTabStyles('single')} onClick={() => navigate('single')}>For One</div>
                    <div className={getTabStyles('share')} onClick={() => navigate('share')}>Sharing</div>
                    <div className={getTabStyles('cart')} onClick={() => navigate('/cart')}>Cart</div>
                </div>
                <Outlet />
            </div>
            <Footer />
        </div>
    );
}

export default Menu;