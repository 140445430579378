import React, { useState, useEffect } from 'react';

import styles from "../css/Home.module.css";

import burger1 from "../images/hamburger.jpg";
import burger2 from "../images/burger.jpg";
import pizza from "../images/pizza.jpg";
import logo from "../images/logo.png";
import ImageSlider from '../components/ImageSlider';

import { useNavigate } from 'react-router-dom';

const Home = () => {

    let navigate = useNavigate();

    const images = [
        {
            src: burger1,
            alt: 'burger1',
        },
        {
            src: burger2,
            alt: 'burger2',
        },
        {
            src: pizza,
            alt: 'pizza',
        }
    ];


    return (
        <div className={styles.container} onClick={() => navigate('/takeaway')}>
            <div className={styles.banner}>

                <ImageSlider images={images} />

                <div className={styles.bannerText}>
                    <h5>Gateway to deliciousness</h5>
                    <h2>ORDER</h2>
                    <h1>HERE</h1>
                </div>
            </div>
            <div className={styles.footer}>
                <div className={styles.logoContainer}>
                    <img src={logo} alt="chicken logo" />
                </div>

                <p>Touch to Order</p>
            </div>
        </div>
    );
}

export default Home;