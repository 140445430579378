import React, { useState } from 'react';
import styles from '../css/OnScreenKeyboard.module.css';
import Key from './Key';
import { AnimatePresence, motion } from 'framer-motion'


const OnScreenKeyboard = ({ title, visible, confirm, cancel }) => {

    const [name, setName] = useState('');

    const topRow = ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p'];
    const middleRow = ['a', 's', 'd', 'f', 'g', 'j', 'h', 'k', 'l'];
    const finalRow = ['z', 'x', 'c', 'v', 'b', 'n', 'm', 'X'];


    const onKeyPress = (key) => {

        if (key == 'X') {
            if (name.length == 0) return;
            let n = name.slice(0, -1);
            setName(n);
        } else {
            let n = name + key;
            setName(n);
        }


    }

    const onConfirm = (e) => {
        e.preventDefault();
        confirm(name);
        setName('');
    }

    const onCancel = (e) => {
        e.preventDefault();
        cancel();
        setName('');
    }



    return (
        <AnimatePresence >
            {
                visible &&
                <motion.div
                    className={styles.container}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                >
                    <motion.form
                        key="myForm"
                        className={styles.card}
                        initial={{ scale: 0 }}
                        animate={{ scale: 1 }}
                    >
                        <p className={styles.heading}>{title}</p>
                        <input type='text' disabled value={name} readOnly />
                        <div className={styles.keyboard}>
                            <div className={styles.row}>
                                {
                                    topRow.map((item, index) => {
                                        return (
                                            <Key onClick={onKeyPress} character={item} key={index} />
                                        )
                                    })
                                }
                            </div>
                            <div className={styles.row}>
                                {
                                    middleRow.map((item, index) => {
                                        return (
                                            <Key onClick={onKeyPress} character={item} key={index} />
                                        )
                                    })
                                }
                            </div>
                            <div className={styles.row}>
                                {
                                    finalRow.map((item, index) => {
                                        return (
                                            <Key onClick={onKeyPress} character={item} key={index} />
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <div className={styles.cntrlButtons}>
                            <button className={styles.cancelBtn} onClick={onCancel}>
                                Cancel
                            </button>
                            <button className={styles.okBtn} onClick={onConfirm}>
                                OK
                            </button>
                        </div>
                    </motion.form>
                </motion.div >

            }
        </AnimatePresence >
    );

}

export default OnScreenKeyboard;