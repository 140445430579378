import './App.css';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

import { RecoilRoot } from 'recoil';

import Home from './screens/Home';
import Takeaway from './screens/Takeaway';
import Menu from './screens/Menu';
import Deals from './screens/Deals';
import Singles from './screens/Singles';
import Share from './screens/Share';
import Cart from './screens/Cart';
import Product from './screens/Product';
import Process from './screens/Process';

function App() {
  return (
    <RecoilRoot>
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/takeaway" exact element={<Takeaway />} />
            <Route path="/product/:id" element={<Product />} />
            <Route path="/menu" exact element={<Menu />}>
              <Route path="deals" exact element={<Deals />} />
              <Route path="single" exact element={<Singles />} />
              <Route path="share" exact element={<Share />} />
            </Route>
            <Route path="/cart" element={<Cart />} />
            <Route path="/process" element={<Process />} />
          </Routes>
        </BrowserRouter>
      </div>
    </RecoilRoot>
  );
}

export default App;
