import React from 'react';
import styles from '../css/Footer.module.css';

import { BiArrowBack, BiHomeAlt, BiCog } from 'react-icons/bi';
import { BsCart } from 'react-icons/bs';

import { useNavigate } from 'react-router-dom';


const Footer = () => {

    let navigate = useNavigate();

    return (
        <div className={styles.container}>
            <div onClick={() => navigate(-1)} className={styles.iconContainer}>
                <BiArrowBack />
            </div>
            <div onClick={() => navigate("/")} className={styles.iconContainer}>
                <BiHomeAlt />
            </div>
            <div onClick={() => navigate("/cart")} className={styles.iconContainer}>
                <BsCart />
            </div>
            <div className={styles.iconContainer}>
                <BiCog />
            </div>
        </div>
    );
}

export default Footer;