import React, { useState, useEffect } from 'react';
import styles from '../css/Product.module.css';
import SelectDropdown from '../components/SelectDropdown';

import product1 from '../images/product1.jpg'
import product2 from '../images/product2.jpg'
import product3 from '../images/product3.png'
import product4 from '../images/product4.png'
import product6 from '../images/product6.png'
import { useParams, useNavigate } from 'react-router-dom';
import { Order } from '../State';
import { useRecoilState } from 'recoil'


const Product = () => {

    const [order, setOrder] = useRecoilState(Order);

    let { id } = useParams();
    let navigate = useNavigate();

    const products = [
        {
            id: 1,
            title: '4pc Chicken Combo',
            ingredients: '4 Spicy Chicken Wings, 1 Potato and Gravy, 1 Softbun, 1 Coke (reg)',
            price: '12.99',
            image: product1,
            categories: ['deals', 'single', 'share'],
            sides: [{ id: 3, quantity: 1, included: true }, { id: 6, quantity: 1, included: true }],
            drinks: [{ id: 4, quantity: 1, included: true }],
            chicken: [{ id: 5, quantity: 4, included: true }],
            burger: [],
        },
        {
            id: 2,
            title: '10pc Spicy Wings',
            ingredients: '10 Spicy Chicken Wings',
            price: '15.99',
            image: product2,
            categories: ['deals', 'single', 'share'],
            sides: [],
            drinks: [],
            chicken: [{ id: 5, quantity: 10, included: true }],
            burger: [],
        },
        {
            id: 3,
            title: 'Potato and Gravy',
            price: '3.99',
            image: product3,
            categories: ['sides']
        },
        {
            id: 4,
            title: 'Coke',
            price: '3.99',
            image: product4,
            categories: ['drinks']
        },
        {
            id: 5,
            title: 'Spicy Wings',
            price: '1.99',
            image: product2,
            categories: ['chicken']
        },
        {
            id: 6,
            title: 'Hot Bun',
            price: '1.99',
            image: product6,
            categories: ['sides']
        },
    ];

    const [product, setProduct] = useState({});

    useEffect(() => {
        if (id) {
            let p = products.filter(x => x.id == id);
            setProduct(p[0]);
        }
    }, []);

    const addToOrder = () => {
        let orderItems = [...order.items, product];
        let myOrder = { ...order, items: orderItems };

        setOrder(myOrder);

        navigate('/cart');
    }

    return (
        <div className={styles.container}>
            {
                product && <>
                    <h1>{product.title}</h1>
                    <div className={styles.preview}>
                        <div className={styles.imageContainer}>
                            <img src={product.image} alt="" />
                        </div>
                        <div className={styles.description}>
                            <p>{product.ingredients}</p>
                            <p className={styles.price}>
                                {product.price}
                            </p>
                        </div>
                    </div>
                    <div className={styles.dropDownContainer}>
                        <SelectDropdown
                            title="Chicken"
                            products={product.chicken}
                        />
                        <SelectDropdown
                            title="Burgers"
                            products={product.burger}
                        />
                        <SelectDropdown
                            title="Sides"
                            products={product.sides}
                        />
                        <SelectDropdown
                            title="Drinks"
                            products={product.drinks}
                        />
                    </div>
                    <div className={styles.footer}>
                        <button className={styles.mnuBtn} onClick={() => navigate(-1)}>
                            Menu
                        </button>
                        <button className={styles.addBtn} onClick={addToOrder}>
                            Add to Order
                        </button>
                    </div>
                </>
            }
        </div>
    );
}

export default Product;