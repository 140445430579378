import React, { useState, useEffect } from 'react';
import styles from '../css/Cart.module.css';
import { Order } from '../State';
import { useRecoilState } from 'recoil'
import Footer from '../components/Footer';
import { useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import OnScreenKeyboard from '../components/OnScreenKeyboard';

const Cart = () => {

    const [order, setOrder] = useRecoilState(Order);
    const [gst, setGST] = useState(0);
    const [total, setTotal] = useState(0);

    const [keyboard, setKeyboard] = useState(false);

    let navigate = useNavigate();

    useEffect(() => {
        if (order) {
            calculateTotal();
        }
    }, [order])

    const calculateTotal = () => {
        if (!order.items) return;

        let sum = 0;
        for (let item of order.items) {
            sum += parseFloat(item.price);
        }

        let gst = parseFloat(sum * 3 / 23);
        setTotal(sum);
        setGST(gst);
    }

    const removeItem = (index) => {
        let orderItems = [...order.items];
        orderItems.splice(index, 1);
        let myOrder = {
            ...order,
            items: orderItems,
        }
        setOrder(myOrder);
    }

    const toggleKeyboard = () => {
        setKeyboard(!keyboard);
    }

    const onConfirm = (name) => {
        let myOrder = {
            ...order,
            name: name,
        }
        setOrder(myOrder);
        toggleKeyboard();
        navigate('/process');
    }

    const onCancel = () => {
        toggleKeyboard();
    }

    return (
        <div className={styles.container}>
            <OnScreenKeyboard
                title="Please enter a name for your order."
                visible={keyboard}
                confirm={onConfirm}
                cancel={onCancel}
            />
            <h1 className={styles.title}>Your Order</h1>
            <div className={styles.orderItemContainer}>
                {
                    order.items && order.items.map((item, index) => {
                        return (
                            <motion.div
                                className={styles.preview}
                                initial={{ x: -100, y: -100, opacity: 0 }}
                                animate={{ x: 0, y: 0, opacity: 1 }}
                                key={index}
                            >
                                <div className={styles.imageContainer}>
                                    <img src={item.image} alt="" />
                                </div>
                                <div className={styles.description}>
                                    <p className={styles.title}>{item.title}</p>
                                    <p>{item.ingredients}</p>
                                    <p className={styles.price}>
                                        {item.price}
                                    </p>
                                </div>
                                <div className={styles.removeBtn} onClick={() => removeItem(index)}>
                                    remove
                                </div>
                            </motion.div>
                        )
                    })
                }
                {
                    order && order.items.length <= 0 ? <p className={styles.noItems}>No Items in Order</p> : null
                }
            </div>
            <div className={styles.totalContainer}>
                <p className={styles.subtitle}>{order.items.length} item(s)</p>
                <p className={styles.amount}>${total.toFixed(2)}</p>
                <p className={styles.subtitle}>Subtotal</p>
                <p className={styles.amount}>${total.toFixed(2)}</p>
                <p className={styles.subtitle}>GST Included (15%)</p>
                <p className={styles.amount}>${gst.toFixed(2)}</p>
                <p className={styles.total}>Total</p>
                <p className={styles.totalAmount}>${total.toFixed(2)}</p>
                <div className={styles.buttonContainer}>
                    <button className={styles.mnuBtn} onClick={() => navigate('/menu/deals')}>
                        Add More Food
                    </button>
                    <button disabled={order && order.items.length > 0 ? false : true} className={styles.nextBtn} onClick={toggleKeyboard}>
                        Place Order
                    </button>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Cart;