import React, { useState } from 'react';
import styles from '../css/SelectDropdown.module.css';

import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import ChangeableItem from './ChangeableItem';

import { motion, AnimatePresence } from 'framer-motion'

const SelectDropdown = ({ title, products }) => {

    const [open, setOpen] = useState(true);

    const toggleOpen = () => {
        setOpen(!open);
    }

    return (
        <div className={styles.container}>
            <div className={styles.caretContainer} onClick={toggleOpen}>
                {open ? <IoIosArrowDown className={styles.red} /> : <IoIosArrowUp />}
            </div>
            <h2>{title}</h2>
            <AnimatePresence>
                {open && <motion.div
                    className={styles.itemBox}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                >
                    {products && products.map((product, index) => {
                        return <ChangeableItem product={product} key={index} />
                    })}
                </motion.div>}
            </AnimatePresence>

        </div>
    );
}

export default SelectDropdown;