import React, { useEffect } from 'react';
import styles from '../css/Process.module.css'
import { Order } from '../State';
import { useRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom'

const Process = () => {

    const [order, setOrder] = useRecoilState(Order);

    let navigate = useNavigate();

    const reset = () => {
        let o = {
            name: 'default',
            items: [],
            orderNo: Math.floor(Math.random() * (9999 - 1000) + 1000)
        }
        setOrder(o);
        navigate('/');
    }

    return (
        <div className={styles.container}>
            <h1>We've got your order</h1>
            <div className={styles.card}>
                <p>
                    Please make your payment and collect your order from the counter once it is ready.
                </p>
                <p className={styles.small}>
                    Your Order Number
                </p>
                <p className={styles.text}>{order && order.orderNo}</p>
                <p className={styles.small}>
                    Your Order Name
                </p>
                <p className={styles.text}>
                    {order.name}
                </p>
            </div>
            <button className={styles.newBtn} onClick={reset}>
                New Order
            </button>
        </div>
    );
}

export default Process;