import React, { useState, useEffect } from 'react';
import styles from '../css/ImageSlider.module.css';
import { motion, AnimatePresence } from "framer-motion"

const ImageSlider = ({ images, stretch }) => {

    const [index, setIndex] = useState(0);

    const anim = {
        enter: {
            x: -1000,
            opacity: 0,
        },
        center: {
            x: 0,
            opacity: 1,
            transition: {
                duration: 1
            },

        },
        exit: {
            x: 1000,
            opacity: 0,
            transition: {
                duration: 0.8
            },
        }
    }

    useEffect(() => {
        if (images && images.length > 0) {
            let interval = setInterval(() => {
                nextSlide();
            }, 3000);


            return () => clearInterval(interval);
        }
    }, [images])

    const nextSlide = () => {
        setIndex(
            prev => {
                return (prev + 1) % images.length;
            }
        )
    }

    const st = {
        width: '100%',
    }

    return (

        <div className={styles.slideContainer} >
            <AnimatePresence initial={false}>
                {
                    images && images.map((image, i) => {
                        if (index === i) {
                            return (

                                <motion.img
                                    key={i}
                                    variants={anim}
                                    initial="enter"
                                    animate="center"
                                    exit="exit"
                                    src={image.src}
                                    alt={image.alt}
                                    style={stretch ? st : null}
                                />
                            )
                        } else {
                            return null;
                        }
                    })
                }
            </AnimatePresence>
        </div>

    );
}

export default ImageSlider;