import React from 'react';
import styles from '../css/Key.module.css';
import { RiDeleteBack2Line } from 'react-icons/ri';

const Key = ({ character, onClick }) => {

    const keyClick = () => {
        onClick(character);
    }

    return (
        <div className={styles.container} onClick={keyClick}>
            {character == 'X' ? <RiDeleteBack2Line /> : character}
        </div>
    );
}

export default Key;
