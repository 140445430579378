import React, { useState, useEffect } from 'react';
import styles from '../css/ChangeableItem.module.css';

import product1 from '../images/product1.jpg'
import product2 from '../images/product2.jpg'
import product3 from '../images/product3.png'
import product4 from '../images/product4.png'
import product6 from '../images/product6.png'

import { motion } from 'framer-motion';

const ChangeableItem = ({ product }) => {

    const products = [
        {
            id: 1,
            title: '4pc Chicken Combo',
            ingredients: '4 Spicy Chicken Wings, 1 Potato and Gravy, 1 Softbun, 1 Coke (reg)',
            price: '12.99',
            image: product1,
            categories: ['deals', 'single', 'share'],
            sides: [{ id: 3, quantity: 1 }, { id: 6, quantity: 1 }],
            drinks: [{ id: 4, quantity: 1 }],
            chicken: [{ id: 5, quantity: 4 }],
        },
        {
            id: 2,
            title: '10pc Spicy Wings',
            ingredients: '10 Spicy Chicken Wings',
            price: '15.99',
            image: product2,
            categories: ['deals', 'single', 'share'],
            sides: [],
            drinks: [],
            chicken: [{ id: 5, quantity: 10 }],
        },
        {
            id: 3,
            title: 'Potato and Gravy',
            price: '3.99',
            image: product3,
            categories: ['sides']
        },
        {
            id: 4,
            title: 'Coke',
            price: '3.99',
            image: product4,
            categories: ['drinks']
        },
        {
            id: 5,
            title: 'Spicy Wings',
            price: '1.99',
            image: product2,
            categories: ['chicken']
        },
        {
            id: 6,
            title: 'Hot Bun',
            price: '1.99',
            image: product6,
            categories: ['sides']
        },
    ];

    const [item, setItem] = useState({});

    useEffect(() => {
        if (product) {
            let p = products.filter(x => x.id == product.id);
            setItem(p[0]);
        }
    }, [])

    return (
        <motion.div
            className={styles.container}
            style={{ originX: 0, originY: 0 }}
            initial={{ scale: 0 }}
            animate={{
                scale: 1, transition: {
                    duration: 0.2
                }
            }}
        >
            <div className={styles.imageContainer}>
                <img src={item.image} alt="" />
            </div>
            <div className={styles.description}>
                {product.included ? <p className={styles.includes}>Comes with meal</p> : <p className={styles.includes}>Extra Side</p>}
                {product.included ? null : <p className={styles.addedPrice}>+{item.price}</p>}
                <p className={styles.title}>{item.title}</p>
                <p className={styles.title}>Qty. {product.quantity}</p>
                <div className={styles.changeBtn}>
                    Change
                </div>
            </div>
        </motion.div>
    );
}

export default ChangeableItem;