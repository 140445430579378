import React from 'react';
import styles from '../css/Single.module.css'

import product1 from '../images/product1.jpg'
import product2 from '../images/product2.jpg'

import ProductList from '../components/ProductList';

const Singles = () => {

    const products = [
        {
            id: 1,
            title: '4pc Chicken Combo',
            price: '12.99',
            image: product1,
            categories: ['deals', 'single', 'share'],
        },
        {
            id: 2,
            title: '10pc Spicy Wings',
            price: '15.99',
            image: product2,
            categories: ['deals', 'single', 'share'],
        },
        {
            id: 2,
            title: '10pc Spicy Wings',
            price: '15.99',
            image: product2,
            categories: ['deals', 'single', 'share'],
        },
    ];

    return (
        <div className={styles.container}>
            <h1>For One</h1>
            <ProductList products={products} />
        </div>
    );
}

export default Singles;